import { Box, HStack, Icon, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { FiExternalLink, FiMail, FiPhone } from "react-icons/fi";
import * as React from "react";

interface ContactInfoPanelProps {
  contactInfo: {
    name?: string;
    email?: string;
    phone?: string;
    website?: string;
    company?: string;
  };
}

export const ContactInfoPanel: React.FC<ContactInfoPanelProps> = ({ contactInfo }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const accentColor = useColorModeValue("blue.600", "blue.200");

  if (!contactInfo) return null;

  const contactLinks = [
    {
      icon: FiMail,
      href: contactInfo.email ? `mailto:${contactInfo.email}` : undefined,
      label: contactInfo.email,
    },
    {
      icon: FiPhone,
      href: contactInfo.phone ? `tel:${contactInfo.phone}` : undefined,
      label: contactInfo.phone,
    },
    {
      icon: FiExternalLink,
      href: contactInfo.website,
      label: "Visit Website",
      isExternal: true,
    },
  ].filter((link) => link.href); // Only show links that have values

  return (
    <Box bg={bgColor} borderRadius="lg" borderWidth="1px" borderColor={borderColor} p={4}>
      <Stack spacing="2">
        {(contactInfo.name || contactInfo.company) && (
          <Stack spacing="1">
            {contactInfo.name && (
              <Text fontSize="md" fontWeight="medium">
                {contactInfo.name}
              </Text>
            )}
            {contactInfo.company && contactInfo.company.trim() !== "" && (
              <Text fontSize={"sm"} color="fg.muted">
                {contactInfo.company}
              </Text>
            )}
          </Stack>
        )}

        {contactLinks.length > 0 && (
          <HStack spacing="4" color="fg.subtle">
            {contactLinks.map((item, id) => (
              <Link
                href={item.href}
                key={id}
                isExternal={item.isExternal}
                display="inline-flex"
                alignItems="center"
                color={accentColor}>
                <HStack spacing="2">
                  <Icon as={item.icon} boxSize="4" />
                  <Text fontSize="sm">{item.label}</Text>
                </HStack>
              </Link>
            ))}
          </HStack>
        )}
      </Stack>
    </Box>
  );
};
