import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../utils/get-cookie";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/deals`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }

    const uniqueBrowserId = getCookie("unique_browser_id");
    if (uniqueBrowserId) {
      // console.log("unique_browser_id found", uniqueBrowserId)
      headers.set("BrowserId", uniqueBrowserId);
    } else {
      // console.log("unique_browser_id not found")
      // headers.set("BrowserId", 'no_id');
    }

    return headers;
  },
});

export const dealsApi = createApi({
  reducerPath: "dealsApi",
  baseQuery: baseQuery,
  tagTypes: ["Deal"],
  endpoints: (builder) => ({
    getDeals: builder.query({
      query: ({ filters, page, sortField, sortOrder }) => ({
        url: "/index",
        method: "POST",
        body: {
          filters: filters,
          page: page,
          sort_field: sortField,
          sort_order: sortOrder,
        },
      }),
      providesTags: (result) =>
        result ? result.data.map((deal) => ({ type: "Deal", id: deal.id })) : [],
    }),
    getDealsForUser: builder.query({
      query: ({ indexType, dealFlowStageId, page, sortField, sortOrder }) => {
        const params = new URLSearchParams();
        if (indexType) params.append("index_type", indexType);
        if (dealFlowStageId) params.append("deal_flow_stage_id", dealFlowStageId);
        if (page) params.append("page", page);
        if (sortField) params.append("sort_field", sortField);
        if (sortOrder) params.append("sort_order", sortOrder);

        return `/index_by_user/${indexType}?${params.toString()}`;
      },
      providesTags: (result) =>
        result ? result.data.map((deal) => ({ type: "Deal", id: deal.id })) : [],
    }),
    getDeal: builder.query({
      query: (dealId) => {
        return `/show/${dealId}`;
      },
      providesTags: (result) => [{ type: "Deal", id: result.data.id }],
      async onQueryStarted(dealId, { dispatch, queryFulfilled, getState }) {
        try {
          const response = await queryFulfilled;
          if (response && response.data) {
            const updatedDeal = response.data.data;
            // Since we don't know the original args we go through every instance of it
            for (const { endpointName, originalArgs } of dealsApi.util.selectInvalidatedBy(
              getState(),
              [{ type: "Deal", id: dealId }]
            )) {
              // If the deal is read we want to mark that deal updated everywhere
              if (["getDealsForUser", "getDeals"].includes(endpointName)) {
                dispatch(
                  dealsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                    const dealIndex = draft.data.findIndex((deal) => deal.id === dealId);
                    if (dealIndex !== -1) {
                      draft.data[dealIndex] = updatedDeal;
                    }
                  })
                );
              }
            }
          } else {
            // console.log("error");
            dispatch(dealsApi.util.invalidateTags(["Deal"]));
          }
        } catch {
          // console.log("error2");
          dispatch(dealsApi.util.invalidateTags(["Deal"]));
        }
      },
    }),
    getDealQualitativeEvents: builder.query({
      query: (dealId) => {
        return `/deal_qualitative_events/${dealId}`;
      },
    }),
    getDealQuantitativeEvents: builder.query({
      query: (dealId) => {
        return `/deal_quantitative_events/${dealId}`;
      },
    }),
    getListingRedirectUrl: builder.query({
      query: (listingId) => {
        return `/get_listing_redirect_url/${listingId}`;
      },
    }),
    userActionOnDeal: builder.mutation({
      query: ({ dealId, secondaryId, actionOnDeal, content }) => ({
        url: `/user_action_on_deal/${actionOnDeal}`,
        method: "POST",
        body: {
          deal_id: dealId,
          secondary_id: secondaryId,
          content: content,
        },
      }),
      async onQueryStarted({ dealId }, { dispatch, queryFulfilled, getState }) {
        try {
          const response = await queryFulfilled;
          if (response && response.data) {
            const updatedDeal = response.data.data;
            // Since we don't know the original args we go through every instance of it
            for (const { endpointName, originalArgs } of dealsApi.util.selectInvalidatedBy(
              getState(),
              [{ type: "Deal", id: dealId }]
            )) {
              if (["getDealsForUser", "getDeals"].includes(endpointName)) {
                dispatch(
                  dealsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                    const dealIndex = draft.data.findIndex((deal) => deal.id === dealId);
                    if (dealIndex !== -1) {
                      draft.data[dealIndex] = updatedDeal;
                    }
                  })
                );
              } else if (endpointName === "getDeal") {
                dispatch(
                  dealsApi.util.updateQueryData("getDeal", originalArgs, (draft) => {
                    draft.data = updatedDeal;
                  })
                );
              }
            }
          } else {
            console.log("error");
            dispatch(dealsApi.util.invalidateTags(["Deal"]));
          }
        } catch {
          console.log("error2");
          dispatch(dealsApi.util.invalidateTags(["Deal"]));
        }
      },
      // We choose to manually update instead of invalidating tags
      // invalidatesTags: (result, error, { dealId }) => [{ type: "Deal", id: dealId }],
    }),
  }),
});

// Selector to find a deal by ID from cached data
export const selectDealById = (state, dealId) => {
  const deals = Object.values(state[dealsApi.reducerPath].queries)
    .map((query) => {
      return query.data.data;
    })
    .flat();
  return deals ? deals.find((deal) => deal.type == "Deal" && deal.id === dealId) : null;
};

export const {
  useGetDealsQuery,
  useGetDealsForUserQuery,
  useGetDealQuery,
  useGetDealQualitativeEventsQuery,
  useGetDealQuantitativeEventsQuery,
  useGetListingRedirectUrlQuery,
  useUserActionOnDealMutation,
} = dealsApi;
