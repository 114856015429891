import {useQuery} from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import {checkout} from "../../../api/checkout";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {CurrentUser} from "../../../graphql/__generated__/CurrentUser";
import {PlanSelectionFooter} from "./footer";
import {PricingCard} from "./pricing-card";

export type PlanRate = "month" | "year";

export const PlanSelectionPage = () => {
  const { loading, data } = useQuery<CurrentUser>(CURRENT_USER_QUERY);
  const [planRate, setPlanRate] = useState<PlanRate>("month");
  const wrapperBgColor = useColorModeValue("gray.50", "gray.800");
  const breakPointValue = useBreakpointValue({ base: "xs", md: "sm" });
  const toast = useToast();

  if (loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  const isActive = ["Pro", "Ultimate"].includes(data?.currentUser?.account?.subscriptionLevel || "Public");
  if (isActive) {
    return <Navigate to={"/search"} replace />;
  }

  return (
    <Box bg={wrapperBgColor} minH="100vh">
      <Stack
        as="section"
        bg={wrapperBgColor}
        py={{ base: "6", md: "14" }}
        px={{ base: "6", md: "8" }}
        // alignItems="center"
        // justifyContent="center"
        // mx="auto"
        // maxW="1500px"
        spacing={8}>
        <Stack spacing="4" direction={{ base: 'column', md: 'row' }} width="full" textAlign={{ base: 'center', md: 'left' }}>
          <Box width={{ base: '75%', md: 'full'}}
               mx={{base: "auto", md: 'none'}}
          >
            <Heading size={breakPointValue} textAlign={{ base: 'center' }}>
              Choose the right Kumo solution for you!
            </Heading>
          </Box>
          {/*<FormControl display="flex" alignItems="center" justifyContent={{ base: 'center', md: 'end' }}>*/}
          {/*  <HStack spacing="4">*/}
              {/*<Switch*/}
              {/*  id="plan-type"*/}
              {/*  isChecked={planRate === "year"}*/}
              {/*  onChange={() => (planRate === "year" ? setPlanRate("month") : setPlanRate("year"))}*/}
              {/*/>*/}
              {/*<FormLabel htmlFor="plan-type" mb="0">*/}
              {/*  Annual Billing ($288/year)*/}
              {/*  <Text color="accent">Save 20% with annual billing!</Text>*/}
              {/*</FormLabel>*/}
          {/*  </HStack>*/}
          {/*</FormControl>*/}
        </Stack>
        <Center>
          <PlanSelectionFooter />
        </Center>
        <Stack direction={{ base: "column", lg: "row" }} spacing={8}
               align={{ base: "stretch" }}  // This ensures that all children stretch to fill the container
               justify={{ base: "center" }}
               pb={"18"}
               shouldWrapChildren={true} >
          <PricingCard
            zIndex={1}
            data={{
              name: "Pro",
              header: "For deals from 100s of brokers & listing sites",
              price: "$100",
              annualPrice: "$1200",
              plan: 'Pro',
              planRate: planRate,
              // additionalPlanDescription: "Everything in Free, plus:",
              features: [
                {
                  title: "Full access to 100K+ deals",
                  description: "View original listing data so you can contact the seller.",
                },
                {
                  title: "Saved searches",
                  description: "Track the deals you care about by industry, date posted, and more",
                },
                {
                  title: "New deal alerts",
                  description: "Get emailed daily or weekly when new deals appear.",
                },
                {
                  title: "Deal management tools",
                  description: "View your history, add notes, and save deals.",
                }
              ],
            }}
            button={
              <Button
                variant="primary"
                size="lg"
                onClick={async () => {
                  (window as any).gtag("event", "redirect_to_stripe", {
                    send_to: "G-EQH88TS87N",
                  });
                  try {
                    const resp = await checkout("Pro", planRate);
                    window.location.replace(resp.checkout_url);
                  } catch (exception: any) {
                    toast({
                      title: exception?.response?.data?.errors || "Whoops something went wrong.",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                      position: "top",
                    });
                  }
                }}>
                Select Pro
              </Button>
            }
          />
          <PricingCard
            zIndex={1}
            isPopular
            data={{
              name: "Ultimate",
              header: "For advanced sourcing and deal analysis",
              price: "$200",
              annualPrice: "$2400",
              plan: 'Ultimate',
              planRate: planRate,
              additionalPlanDescription: "Everything in Pro, plus:",
              features: [
                {
                  title: "CSV Exports",
                  description: "Export deals so you can work with them in Excel.",
                },
                {
                  title: "Historical Data",
                  description: "Track listing changes (e.g., drops in price).",
                },
                {
                  title: "Analytics",
                  description: "Robust data and infographics to help look at key metrics.",
                }
              ],
            }}
            button={
              <Button
                variant="primary"
                size="lg"
                onClick={async () => {
                  (window as any).gtag("event", "redirect_to_stripe", {
                    send_to: "G-EQH88TS87N",
                  });
                  try {
                    const resp = await checkout("Ultimate", planRate);
                    window.location.replace(resp.checkout_url);
                  } catch (exception: any) {
                    toast({
                      title: exception?.response?.data?.errors || "Whoops something went wrong.",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                      position: "top",
                    });
                  }
                }}>
                Select Ultimate
              </Button>
            }
          />

          {/*<PricingCard*/}
          {/*  data={{*/}
          {/*    header: "For testing Kumo, or if you aren't ready to buy a business",*/}
          {/*    price: "$0",*/}
          {/*    annualPrice: "$0",*/}
          {/*    plan: 'Free',*/}
          {/*    planRate: planRate,*/}
          {/*    name: "Free",*/}
          {/*    additionalPlanDescription: "Key features:",*/}
          {/*    features: [*/}
          {/*      {*/}
          {/*        title: "Incomplete data",*/}
          {/*        description: "Free users cannot view seller contact info or historical data.",*/}
          {/*      },*/}
          {/*      {*/}
          {/*        title: "Search restrictions",*/}
          {/*        description: "Only deals older than 30 days, and limited search filters.",*/}
          {/*      },*/}
          {/*      {*/}
          {/*        title: "No email alerts",*/}
          {/*        description: "No notifications when new deals are on the market.",*/}
          {/*      },*/}
          {/*    ],*/}
          {/*  }}*/}
          {/*  button={*/}
          {/*    <Button variant="outline" size="lg" as={ReactRouterLink}  to="/search">*/}
          {/*      Continue with free plan*/}
          {/*    </Button>*/}
          {/*  }*/}
          {/*/>*/}


        </Stack>
      </Stack>
    </Box>
  );
};
