import {Box, Link, Stack, Text, useBreakpointValue, useColorModeValue,} from "@chakra-ui/react";
import React from "react";

export const PlanSelectionFooter = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      bg="bg.surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      px={{ base: "5"}}
      py={{ base: "5"}}
      position="relative"
      width={{base: "full", lg: "1032px"}}
      borderRadius="xl">
      <Stack
        direction={{ base: "column", lg: "row" }}
        justify="center"
        align={{ base: "center" }}
        spacing={{ base: "3", lg: "2" }}>
        <Stack
          spacing="3"
          direction={{ base: "column", lg: "row" }}
          align={{ base: "center" }}
        >
          <Text fontWeight="medium" align={{base: "center", lg: "start"}}>
            Both the Pro and Ultimate plans have a 30 day money-back guarantee!
            <Box display={{ base: 'block', lg: 'none'}} />
            {/*<Box as="span" color={"fg.muted"} fontWeight={""}>*/}
            {/*  Browsing with limited search & data is free!{" "}*/}
            {/*</Box>*/}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
