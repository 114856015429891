import {useParams} from "react-router-dom";
import {
  Box,
  Center,
  Flex,
  Icon,
  Skeleton,
  SkeletonText,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import {useGetDealQuery} from "../../services/dealsApi";
import {DealHeader} from "./sections/deal-header";
import {Listings} from "./sections/listings";
import {AboutThisBusiness} from "./sections/about-this-business";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {HeadingContext} from "../heading-provider";
import {StandardTextHeading} from "../layouts/BodyHeading";
import {UserActionsPanel} from "../../components/deal/user-actions-panel";
import {Note, NotesInterface} from "../../components/deal/notes-interface";
import Helmet from "react-helmet";
import {NotesButton} from "../../components/deal/notes-button";
import {ChangeEvents} from "./sections/change-events";
import {Analytics} from "./sections/analytics";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../../components/upgrade-ctas/switch-to-paid-modal";
import {MdLockOutline} from "react-icons/md";
import {SignupForFreeModal} from "../../components/upgrade-ctas/signup-for-free-modal";
import { ContactInfoPanel } from "../../components/deal/contact-info-panel";

export const DealPage = (props) => {
  const { temporaryDeal } = props;
  const { dealId } = useParams();
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });

  if (props.modalDealId) {
    const { data: dealData, isLoading, isFetching, isError } = useGetDealQuery(props.modalDealId);
    const deal = dealData?.data || temporaryDeal;

    return (
      <MobileFriendlyDeal deal={deal} dealData={dealData} />
    );
  } else if (dealId) {
    return (
      <StandaloneDealPage dealId={dealId} />
    )
  }
};

const TextHeading = ({ text }) => {
  return (
    <Text fontSize={"lg"} fontWeight="semibold">
      {text}
    </Text>
  );
}

export const MobileFriendlyDeal = ({deal, dealData}) => {
  const showNewFeatures = process.env.REACT_APP_SHOW_NEW_FEATURES === "true" || false;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isUltimate = ["Ultimate"].includes(subscriptionLevel);
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  const hasListingHistoryEvents = deal?.attributes?.total_qual_event_count > 0 || deal?.attributes?.total_quant_event_count > 0;

  const dealSectionTypes = [
    {name: 'Overview'},
  ];

  dealSectionTypes.push({name: 'Your Notes', subscriptionTiers: ["Pro", "Ultimate"]});
  dealSectionTypes.push({ name: 'Analytics', subscriptionTiers: ["Ultimate"] });
  if (isUltimate) {
    dealSectionTypes.push({ 
      name: `Listing History ${hasListingHistoryEvents ? '' : '(0)'}`, 
      disabled: !hasListingHistoryEvents, 
      subscriptionTiers: ["Ultimate"] 
    });
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [plan, setPlan] = useState("Pro");

  const handleTabChange = (index) => {
    const type = dealSectionTypes[index];
    if (type.subscriptionTiers && !type.subscriptionTiers.includes(userData?.currentUser?.account?.subscriptionLevel || "Public")) {
      setPlan(type.subscriptionTiers[0])
      setTabIndex(tabIndex)
    } else {
      setTabIndex(index)
    }
  }

  const renderContactInfo = () => {
    if (!deal?.attributes?.only_show_contact_info) return null;

    const contactInfo = {
      name: deal.attributes.contact_name,
      email: deal.attributes.contact_email,
      phone: deal.attributes.contact_phone,
      website: deal.attributes.contact_website,
      company: deal.attributes.contact_company,
    };

    return (
      <Box mt={4}>
        <ContactInfoPanel contactInfo={contactInfo} />
      </Box>
    );
  };

  return (
    <Box as="section" bg="bg-surface" position="relative">
      <Helmet>
        <title>{`Kumo | ${deal ? deal.attributes.title : "Loading..."}`}</title>
        <meta name="description" content={deal ? deal.attributes.business_summary : "Deal details loading..."} />
        <meta property="og:title" content={`Deal | ${deal ? deal.attributes.title : "Loading..."}`} />
        <meta property="og:description" content={deal ? deal.attributes.business_summary : "Deal details loading..."} />
        <meta property="og:image" content={deal ? deal.attributes.thumbnail_photo_url : "https://storage.googleapis.com/kumo-deal-photos/kumo-icon.png"} />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Box mb={"20px"}>
        {deal && <UserActionsPanel deal={deal} />}
      </Box>

      <DealHeader deal={deal} />
      <Tabs pt={"8"} size={'md'} variant="underline" index={tabIndex} onChange={handleTabChange} isLazy>
        <TabList>
          {dealSectionTypes.map((type, index) => {
              if (type.subscriptionTiers && !type.subscriptionTiers.includes(userData?.currentUser?.account?.subscriptionLevel || "Public")) {
                return (
                  <Tab key={index} onClick={onOpen}>
                    <Icon as={MdLockOutline} w={4} h={4} color="gray.500" mr={"1"}/>
                    <Tooltip label="Upgrade to access this feature" hasArrow>
                      {type.name}
                    </Tooltip>
                  </Tab>
                )

              } else {
                return (
                  <Tab key={index} opacity={(type.disabled && index != tabIndex) ? '0.4' : '1'}>
                    {type.disabled ? (
                      <Tooltip label="No change history for this listing" hasArrow>
                        {type.name}
                      </Tooltip>
                    ) : (
                      type.name
                    )}
                  </Tab>
                )
              }
          })}
        </TabList>
        <TabIndicator />
        <TabPanels>
          <TabPanel px={"0"}>
            <Box mb={"20px"}>
              {deal?.attributes?.only_show_contact_info ? (
                <>
                  <TextHeading text={"Contact Info"} />
                  {renderContactInfo()}
                </>
              ) : (
                <>
                  <TextHeading text={"Listings"} />
                  <Listings deal={dealData?.data ? deal : null} />
                </>
              )}
            </Box>
            <Box my={"40px"}>
              <TextHeading text={"About This Business"} />
              <AboutThisBusiness deal={deal} />
            </Box>
          </TabPanel>
          {isPaid && <TabPanel px={"0"}>
            <Box mb={"20px"}>
              <TextHeading text={"Your Notes"} />
            </Box>
            <Box mt={"10px"}>
              <NotesInterface deal={deal} />
            </Box>
          </TabPanel>}
          {isUltimate && <TabPanel px={"0"}>
            <AnalyticsSection deal={deal} />
          </TabPanel>}
          {isUltimate && <TabPanel px={"0"}>
            <ChangeEventsSection deal={deal} />
          </TabPanel>}
        </TabPanels>
      </Tabs>
      {isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
      {isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={plan} />}
    </Box>
  )
}

export const RecentNotes = ({deal}) => {
  const notes = deal?.attributes?.notes || [];

  if (notes && notes.length > 0) {
    return (
      <Box py={"0px"}>
        <Stack spacing="5">
          {notes.slice(0, 3).map((note) => (
            <Note key={note.id} note={note} deal={deal}/>
          ))}
        </Stack>
        <Box mt={"15px"}>
          <NotesButton deal={deal} buttonType={'textLink'} buttonText={"See all notes"} />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box py={"0px"}>
        <Box>
          <NotesButton deal={deal} buttonType={'textLink'} buttonText={"Add a note"} />
        </Box>
      </Box>
    )
  }
}

const AnalyticsSection = ({deal}) => {
  return (
    <>
      <Box mb={"10px"}>
        <TextHeading text={"Analytics"} />
      </Box>
      <Analytics deal={deal} />
    </>
  )
}

const ChangeEventsSection = ({deal}) => {
  return (
    <>
      <Box mb={"20px"}>
        <TextHeading text={"Listing History"} />
      </Box>
      <ChangeEvents deal={deal} />
    </>
  )
}

export const StandaloneDealPage = ({dealId}) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });

  const { data: dealData, isLoading, isFetching, isError } = useGetDealQuery(dealId);
  const deal = dealData?.data;

  const { setHeadingContent } = useContext(HeadingContext);
  useEffect(() => {
    const pageName = deal ? `Detailed Deal View` : `Deal Loading...`;
    setHeadingContent(<StandardTextHeading text={pageName} />);
  }, [setHeadingContent, deal]);

  if ( isLoading || isFetching ) {
    return (
      <Box px={4} pt={"20px"} pb={"100px"}>
        <Center w="100%" h="150px">
          <Spinner />
        </Center>
      </Box>
    )
  }

  if (!isDesktop) {
    return (
      <Box px={4} pt={"20px"} pb={"100px"}>
        <MobileFriendlyDeal deal={deal} dealData={dealData} />
      </Box>
    );
  } else {
    return (
      <FullWidthDealPage deal={deal} dealData={dealData} />
    )
  }
}

const FullWidthDealPage = ({deal, dealData}) => {
  const showNewFeatures = process.env.REACT_APP_SHOW_NEW_FEATURES === "true" || false;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isUltimate = ["Ultimate"].includes(subscriptionLevel);
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  const hasListingHistoryEvents = deal?.attributes?.total_qual_event_count > 0 || deal?.attributes?.total_quant_event_count > 0;

  const dealSectionTypes = [
    {name: 'Overview'},
  ]
  dealSectionTypes.push({ name: 'Analytics', subscriptionTiers: ["Ultimate"] });
  if (isUltimate) {
    dealSectionTypes.push({ name: `Listing History ${hasListingHistoryEvents ? '' : '(0)'}`, disabled: !hasListingHistoryEvents, subscriptionTiers: ["Ultimate"] });
  } else {
    dealSectionTypes.push({ name: `Listing History`, disabled: !hasListingHistoryEvents, subscriptionTiers: ["Ultimate"] });
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [plan, setPlan] = useState("Pro");

  const handleTabChange = (index) => {
    const type = dealSectionTypes[index];
    if (type.subscriptionTiers && !type.subscriptionTiers.includes(userData?.currentUser?.account?.subscriptionLevel || "Public")) {
      setPlan(type.subscriptionTiers[0])
      setTabIndex(tabIndex)
    } else {
      setTabIndex(index)
    }
  }

  const renderContactInfo = () => {
    if (!deal?.attributes?.only_show_contact_info) return null;

    const contactInfo = {
      name: deal.attributes.contact_name,
      email: deal.attributes.contact_email,
      phone: deal.attributes.contact_phone,
      website: deal.attributes.contact_website,
      company: deal.attributes.contact_company,
    };

    return (
      <Box mt={4}>
        <ContactInfoPanel contactInfo={contactInfo} />
      </Box>
    );
  };

  return (
    <Flex h="100vh" w="100%">
      {/* Scrollable Container */}
      <Box flex="2" overflowY="auto" p="8">
        <Box as="section" bg="bg-surface" position="relative">
          <Helmet>
            <title>{`Kumo | ${deal ? deal.attributes.title : "Loading..."}`}</title>
            <meta name="description" content={deal ? deal.attributes.business_summary : "Deal details loading..."} />
            <meta property="og:title" content={`Deal | ${deal ? deal.attributes.title : "Loading..."}`} />
            <meta property="og:description" content={deal ? deal.attributes.business_summary : "Deal details loading..."} />
            <meta property="og:image" content={deal ? deal.attributes.thumbnail_photo_url : "https://storage.googleapis.com/kumo-deal-photos/kumo-icon.png"} />
            {/* Add other meta tags as needed */}
          </Helmet>
          <DealHeader deal={deal} />
          <Tabs pt={"8"} size={'md'} variant="underline" index={tabIndex} onChange={handleTabChange} isLazy>
            <TabList>
              {dealSectionTypes.map((type, index) => {
                if (type.subscriptionTiers && !type.subscriptionTiers.includes(userData?.currentUser?.account?.subscriptionLevel || "Public")) {
                  return (
                    <Tab key={index} onClick={onOpen}>
                      <Icon as={MdLockOutline} w={4} h={4} color="gray.500" mr={"1"}/>
                      <Tooltip label="Upgrade to access this feature" hasArrow>
                        {type.name}
                      </Tooltip>
                    </Tab>
                  )

                } else {
                  return (
                    <Tab key={index} opacity={(type.disabled && index != tabIndex) ? '0.4' : '1'}>
                      {type.disabled ? (
                        <Tooltip label="No change history for this listing" hasArrow>
                          {type.name}
                        </Tooltip>
                      ) : (
                        type.name
                      )}
                    </Tab>
                  )
                }
              })}
            </TabList>
            <TabIndicator />
            <TabPanels>
              <TabPanel px={"0"}>
                <Box mb={"20px"}>
                  {deal?.attributes?.only_show_contact_info ? (
                    <>
                      <TextHeading text={"Contact Info"} />
                      {renderContactInfo()}
                    </>
                  ) : (
                    <>
                      <TextHeading text={"Listings"} />
                      <Listings deal={dealData?.data ? deal : null} />
                    </>
                  )}
                </Box>
                <Box my={"40px"}>
                  <TextHeading text={"About This Business"} />
                  <AboutThisBusiness deal={deal} />
                </Box>
              </TabPanel>
              {isUltimate && <TabPanel px={"0"}>
                <AnalyticsSection deal={deal} />
              </TabPanel>}
              {isUltimate && <TabPanel px={"0"}>
                <ChangeEventsSection deal={deal} />
              </TabPanel>}
            </TabPanels>
          </Tabs>
          {isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
          {isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={plan} />}
        </Box>
      </Box>

      {/* Fixed Container */}
      <Box flex="1" p="8" borderLeft={"1px"} borderColor={"gray.200"}>
        <Box mb={"20px"}>
          {deal && <UserActionsPanel deal={deal} />}
          {!deal &&  <Skeleton height={"35px"} />}
        </Box>
        <Box my={"30px"}>
          <TextHeading text={"Recent Notes"} />
          <Box mt={"10px"}>
            {deal && <RecentNotes deal={deal} />}
            {!deal &&  <SkeletonText mt="4" noOfLines={2} spacing="3" skeletonHeight="7" />}
          </Box>
        </Box>
        {/* Content here will remain fixed */}
      </Box>
    </Flex>
  );
}